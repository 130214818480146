import React, { useContext, useState } from "react";
import { navigate, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainBlue,
  mainBlack,
  screen,
  mainRed,
  transHover,
} from "../components/variables";
import { motion } from "framer-motion";
import { CgTrash } from "react-icons/cg";
import { toSlug } from "../helpers";
import emailjs from "emailjs-com";
import { StoreContext } from "../context";
import { ImArrowLeft2 } from "react-icons/im";

const Wrapper = styled.div`
  padding: 70px 0 0 0;
  background: ${mainWhite};
  color: ${mainBlue};
  min-height: calc(60vh);
  @media ${screen.xsmall} {
    min-height: calc(100vh - 490px);
  }

  .hero {
    .heading {
      margin: 0 0 25px 0;
      font-size: 1.4rem;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }

    p {
      font-size: 0.95rem;
      max-width: 490px;
    }
  }

  .quote {
    margin: 50px 0 0 0;

    .group {
      padding: 0 0 30px 0;
      @media ${screen.xsmall} {
        padding: 0 0 50px 0;
      }

      .label,
      .product {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        color: ${mainBlack};

        .col {
          &--one {
            width: 100%;
            @media ${screen.xsmall} {
              width: 66%;
              padding: 0 75px 0 0;
            }
            @media ${screen.small} {
              width: 56%;
            }
            @media ${screen.medium} {
              padding: 0;
            }

            span {
              text-transform: uppercase;
            }
          }

          &--two {
            width: 100%;
            padding: 0 0 0 110px;
            @media ${screen.xsmall} {
              width: 24%;
              padding: 0;
            }
            @media ${screen.small} {
              width: 22%;
            }

            .label-mobile {
              display: block;
              font-size: 0.9rem;
              font-weight: 500;
              margin: 16px 0 5px 0;
              @media ${screen.xsmall} {
                display: none;
              }
            }
          }

          &--three {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            @media ${screen.xsmall} {
              width: 10%;
              margin: 0;
            }
            @media ${screen.small} {
              width: 22%;
            }
          }

          &--prod {
            display: flex;
            align-items: center;

            .img {
              min-width: 110px;
              max-width: 110px;
              padding: 0 15px 0 0;
            }

            .txt {
              .prod-name {
                cursor: pointer;
                font-size: 1rem;
                margin: 0 0 20px 0;
                line-height: 1.35;
                @media ${screen.xsmall} {
                  font-size: 1.45rem;
                }
              }

              .prod-num {
                font-size: 0.9rem;
                margin: 0 0 4px 0;
                @media ${screen.xsmall} {
                  font-size: 1rem;
                }

                span {
                  text-decoration: underline;
                }
              }

              .prod-min {
                font-size: 0.9rem;
                @media ${screen.xsmall} {
                  font-size: 1rem;
                }
              }
            }
          }

          &--kind {
            font-size: 0.94rem;
            font-weight: 500;
            @media ${screen.xsmall} {
              font-size: 1rem;
            }
          }

          &--qty {
            display: none;
            @media ${screen.xsmall} {
              display: block;
            }
          }

          &--remove-btn {
            white-space: pre;
            margin: 30px 0 0 0;
            @media ${screen.xsmall} {
              margin: 0;
              padding: 0 25px 0 0;
            }

            span {
              font-size: 0.74rem;
              cursor: pointer;
              @media ${screen.xsmall} {
                font-size: 0.8rem;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }

          .input {
            all: unset;
            border: 1px ${mainBlack} solid;
            width: 45px;
            text-align: center;
            font-size: 16px;
            outline: none;
            padding: 6px 5px 4px 5px;
            font-weight: 300;
            @media ${screen.xsmall} {
              margin: 0 0 0 -17px;
              width: 55px;
              font-size: 1.15rem;
            }
          }
        }
      }

      .label {
        border-bottom: 2px ${mainBlack} solid;
        padding: 0 0 3px 0;
        @media ${screen.xsmall} {
          border-top: 2px ${mainBlack} solid;
          padding: 7px 0 0 0;
          border-bottom: 0;
        }
      }

      .product {
        margin: 0 0 20px 0;
        border-bottom: 2px #e8e8ea solid;
        padding: 20px 0 6px 0;
        @media ${screen.xsmall} {
          padding: 20px 0;
        }
        &:last-child {
          @media ${screen.xsmall} {
            /* border-bottom: 0; */
          }
        }
      }
    }

    .clear {
      display: flex;
      justify-content: flex-end;
      margin: 0 0 20px 0;

      span {
        font-size: 0.8rem;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        color: ${mainBlue};
        transition: ${transHover};
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainRed};
          }
        }
      }

      svg {
        font-size: 1.1rem;
        vertical-align: middle;
        @media ${screen.xsmall} {
          margin: -4px 0 0 0;
        }
      }
    }
  }

  .enquiry-details {
    .heading {
      color: ${mainBlue};
      margin: 76px 0 38px 0;
      font-size: 1.4rem;

      @media ${screen.xsmall} {
        font-size: 1.95rem;
        margin: 0 0 38px 0;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }

    .form {
      max-width: 880px;
      padding: 0 0 120px 0;
    }

    .row {
      display: flex;
      margin: 0 -7px 24px -7px;
      @media ${screen.xsmall} {
        margin: 0 -12px 24px -12px;
      }

      .col {
        width: 50%;
        margin: 0 7px;
        @media ${screen.xsmall} {
          margin: 0 12px;
        }
      }

      label {
        display: block;
        margin: 0 0 3px 0;
        font-size: 0.84rem;
        @media ${screen.xsmall} {
          font-size: 1rem;
          margin: 0 0 6px 0;
        }
      }

      input {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100%;
        font-size: 16px;
        outline: none;
        border-radius: 0;
        border: 2px #e8e8ea solid;
        padding: 12px;
        display: block;
      }

      &--full {
        display: block;
        margin: 0;

        textarea {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          display: block;
          font-size: 16px;
          width: 100%;
          resize: none;
          outline: none;
          height: 140px;
          border: 2px #e8e8ea solid;
          padding: 12px;
        }
      }

      &--btns {
        justify-content: space-between;
        align-items: flex-start;
        margin: 26px 0 0 0;
        @media ${screen.xsmall} {
          margin: 36px 0 0 0;
        }

        .btn {
          border: 2px ${mainBlue} solid;
          width: 100%;
          text-align: center;
          @media ${screen.xsmall} {
            width: auto;
          }
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainBlue};
              color: ${mainWhite};
            }
          }

          &--back {
            text-transform: uppercase;
            display: flex;
            align-items: center;
            position: relative;
            padding: 8px 25px 4px 44px;
            display: none;
            @media ${screen.xsmall} {
              display: inline-block;
            }

            svg {
              font-size: 1rem;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 17px;
            }
          }

          &--mobile {
            display: inline-block;
            width: 100%;
            text-align: center;
          }
        }
      }

      &--mobile {
        margin: 0;
        @media ${screen.xsmall} {
          display: none;
        }
      }
    }
  }
`;

const organizeOrder = (arr) => {
  const obj = arr.reduce((obj, each) => {
    const kind = each.kind;
    if (!obj[kind]) obj[kind] = [];
    obj[kind].push(each);
    return obj;
  }, {});

  return obj;
};

const QuotePage = ({ data }) => {
  const [userInput, setUserInput] = useState({});
  const [isSending, setIsSending] = useState(false);
  const { orders, updateOrder, removeOrder, clearQuote } = useContext(
    StoreContext
  );

  const filteredOrders = organizeOrder(orders);

  const renderProductList = Object.keys(filteredOrders).map((item, i) => {
    return (
      <div className="group" key={i}>
        <div className="label">
          <div className="col col--one col--kind">
            <span>{item}</span>
          </div>
          <div className="col col--two col--qty">
            <span>QTY</span>
          </div>
          <div className="col col--three col--clear"></div>
        </div>
        {filteredOrders[item].map((prod) => (
          <div key={prod.id} className="product">
            <div className="col col--one col--prod">
              <div className="img">
                {prod.image ? (
                  <GatsbyImage image={prod.image} alt="Product preview" />
                ) : (
                  <StaticImage
                    src="../images/fallback-gallery.jpg"
                    alt="Product preview"
                  />
                )}
              </div>
              <div className="txt">
                <h6
                  className="prod-name"
                  onClick={() => {
                    navigate(`/products/${toSlug(prod.name)}/`);
                  }}
                >
                  {prod.name}
                </h6>
                <p className="prod-num">
                  Item Number: <span>#{prod.id}</span>
                </p>
                <p className="prod-min">
                  Min. Order Quantity: {prod.minOrder || 0} Units
                </p>
              </div>
            </div>
            <div className="col col--two">
              <label className="label-mobile">QTY</label>
              <input
                className="input"
                type="text"
                value={prod.quantity}
                onChange={(e) => {
                  updateOrder(prod.id, e.target.value);
                }}
              />
            </div>
            <div className="col col--three col--remove-btn">
              <motion.span
                whileTap={{ scale: 0.96 }}
                role="button"
                onClick={() => {
                  removeOrder(prod.id);
                }}
              >
                REMOVE ITEM
              </motion.span>
            </div>
          </div>
        ))}
      </div>
    );
  });

  const isProducts = renderProductList.length !== 0;

  const handleChange = (e) => {
    userInput[e.target.name] = e.target.value;

    setUserInput({
      ...userInput,
    });
  };

  const {
    first_name,
    last_name,
    email_address,
    company,
    postcode,
    message,
    contact_number,
  } = userInput;

  const ordersHtmlTemplate = `
          <p>
          <strong>Product quote for: </strong>
          </p>
          <ul>
            ${orders
              .map(
                (item) =>
                  `<li>#${item.id} - ${item.name} - quantity(${item.quantity})</li>`
              )
              .join("")}
          </ul>
          <p>
            <strong>First name: </strong>${first_name}
          </p>
          <p>
            <strong>Last name: </strong>${last_name}
          </p>
          <p>
            <strong>Company: </strong>${company}
          </p>
          <p>
            <strong>Contact number: </strong>${contact_number}
          </p>
          <p>
            <strong>Postcode: </strong>${postcode}
          </p>
          <p>
            <strong>Email address: </strong>${email_address}
          </p>
          <p>
            <strong>Message: </strong>${message}
          </p>`;

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSending(true);

    emailjs
      .send(
        "service_2mx6w69",
        "template_2zfindk",
        { first_name, html: ordersHtmlTemplate },
        "user_GrbU2PqIALC99ZsBnzzrK"
      )
      .then(() => {
        navigate("/submitted/", { state: { form: "quote" } });
        setUserInput({});
      })
      .catch(() => {
        setIsSending(false);
        alert("Sorry, Something went wrong. Please contact us immediately.");
      });
  };

  return (
    <Layout rotatedSquareColour={mainWhite}>
      <Seo
        title={"Quote"}
        description={
          "This is a quote request only. A representative from F-Pak Wholesalers will be in contact with you as soon as possible to discuss your needs and assist you with placing an order."
        }
      />
      <Wrapper>
        <header className="hero">
          <div
            className="inner-wrapper"
            data-sal="fade"
            data-sal-duration="500"
          >
            <h1 className="heading">
              {isProducts
                ? "Your Quote Request"
                : "Your enquiry is currently empty"}
            </h1>
            <p>
              {isProducts
                ? "This is a quote request only. A representative from F-Pak Wholesalers will be in contact with you as soon as possible to discuss your needs and assist you with placing an order."
                : "Add products to receive a comprehensive price quote and information for your convenience."}
            </p>
          </div>
        </header>

        {isProducts && (
          <section className="quote" data-sal="fade" data-sal-duration="600">
            <div className="inner-wrapper">
              <div className="clear">
                <motion.span
                  whileTap={{ scale: 0.96 }}
                  role="button"
                  onClick={() => {
                    clearQuote();
                  }}
                >
                  CLEAR QUOTE <CgTrash />
                </motion.span>
              </div>
              {renderProductList}
            </div>
          </section>
        )}

        {isProducts && (
          <section className="enquiry-details">
            <div className="inner-wrapper">
              <div className="row row--btns row--mobile">
                <Link className="btn btn--back btn--mobile" to="/products/">
                  <ImArrowLeft2 />
                  CONTINUE SHOPPING
                </Link>
              </div>
              <h3 className="heading">Your details</h3>
              <form className="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col">
                    <label>First Name*</label>
                    <input
                      name="first_name"
                      onChange={handleChange}
                      value={userInput.first_name || ""}
                      type="text"
                      required
                    />
                  </div>
                  <div className="col">
                    <label>Last Name*</label>
                    <input
                      name="last_name"
                      onChange={handleChange}
                      value={userInput.last_name || ""}
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Company</label>
                    <input
                      name="company"
                      onChange={handleChange}
                      value={userInput.company || ""}
                      type="text"
                    />
                  </div>
                  <div className="col">
                    <label>Contact Number</label>
                    <input
                      name="contact_number"
                      onChange={handleChange}
                      value={userInput.contact_number || ""}
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Postcode</label>
                    <input
                      name="postcode"
                      onChange={handleChange}
                      value={userInput.postcode || ""}
                      type="text"
                    />
                  </div>
                  <div className="col">
                    <label>Email Address*</label>
                    <input
                      name="email_address"
                      onChange={handleChange}
                      value={userInput.email_address || ""}
                      type="email"
                      required
                    />
                  </div>
                </div>
                <div className="row row--full">
                  <label>Message*</label>
                  <textarea
                    name="message"
                    onChange={handleChange}
                    value={userInput.message || ""}
                    required
                  />
                </div>
                <div className="row row--btns">
                  <Link className="btn btn--back" to="/products/">
                    <ImArrowLeft2 />
                    CONTINUE SHOPPING
                  </Link>
                  <motion.button whileTap={{ scale: 0.96 }} className="btn">
                    {isSending ? "PLEASE WAIT..." : "SUBMIT"}
                  </motion.button>
                </div>
              </form>
            </div>
          </section>
        )}
      </Wrapper>
    </Layout>
  );
};

export default QuotePage;
